import React from "react";
import t from "prop-types";
import { Grid } from "@mui/material";
import { Card } from "components/Utilities";
import { useStyles } from "styles/formStyles";

const VerificationCard = ({ name, setName, title, icon, iconBgColor }) => {
  const classes = useStyles();
  return (
    <Grid
      item
      className={
        name ? `${classes.parentGrid} ${classes.active}` : classes.parentGrid
      }
      onClick={() => {
        setName && setName(!name);
      }}
    >
      <Card title={title}>
        <Grid
          sx={{
            backgroundColor: iconBgColor,
            width: "86px",
            height: "86px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid className={classes.iconWrapper}>{icon}</Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

VerificationCard.propTypes = {
  name: t.string.isRequired,
  iconBgColor: t.string.isRequired,
  setName: t.func,
  title: t.string.isRequired,
  icon: t.node.isRequired,
};

export default VerificationCard;
