import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  gridWrapper: {
    "&.MuiGrid-root": {
      width: "230px",
      height: "250px",
      borderRadius: "20px",
    },
  },

  iconWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Card = ({ title, background, children, header = "h4", padding }) => {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      gap={2}
      className={classes.gridWrapper}
    >
      <Grid item container className={classes.iconWrapper}>
        {children}
      </Grid>
      <Grid item textAlign="center" sx={{ width: "100%" }}>
        <Typography
          variant={header}
          sx={{
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "36px",
            textAlign: "center",
            color: "#2D2F39",
          }}
        >
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  background: PropTypes.string,
  header: PropTypes.string,
  children: PropTypes.node,
  padding: PropTypes.string,
};

export default Card;
