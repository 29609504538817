export const stepReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_STEP":
      const newState = { step: action?.step };
      return newState;

    default:
      return state;
  }
};
