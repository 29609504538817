import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

const FormContainer = ({ children }) => {
  return (
    <Grid
      sx={{
        backgroundColor: "#fff !important",
        borderRadius: "16px",
        padding: "3rem",
        position: "relative",
        zIndex: 50,
      }}
    >
      {children}
    </Grid>
  );
};

FormContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormContainer;
