import React from "react";
import { ErrorMessage } from "formik";
import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import { Grid, Typography } from "@mui/material";

import { Loader } from "./Utilities";
import styled from "styled-components";
import { CloseBtn } from "./Utilities/Button";
import { TextError } from "./Utilities/TextError";
import imageIcon from "assets/images/image.svg";
import {
  compressAndUploadImage,
  showErrorMsg,
  showSuccessMsg,
  uploadImage,
} from "helpers/helperFuncs";
import { makeStyles } from "@mui/styles";
import { FormikControl } from "./validation";

const useStyles = makeStyles((theme) => ({
  dragAndDropCont: {
    "&.div": {
      [theme.breakpoints.down("md")]: {
        display: "none",
        width: "100%",
      },
    },
  },

  fileUpload: {
    "&.div": {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
  },
}));

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#c4c4c4";
};

const Container = styled.div`
  min-height: 250px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: transparent;
  color: #777171;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  backgroundColor: "#eaeaea",
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
  position: "relative",
};

const thumbInner = {
  display: "flex",
  backgroundColor: "#eaeaea",
  minWidth: 0,
  overflow: "hidden",
  position: "relative",
};

const img = {
  display: "block",
  backgroundColor: "#eaeaea",
  width: "auto",
  height: "100%",
};

const errorContainer = {
  margin: "1rem 0rem",
};

const DragAndDrop = ({ name, setFieldValue, maxFiles }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [preview, setPreview] = React.useState("");
  const [progress, setProgress] = React.useState();
  const [isCompleted, setIsCompleted] = React.useState(null);
  const [isCompressing, setIsCompressing] = React.useState(false);
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    accept: "image/*",
    maxFiles: maxFiles,
    autoFocus: true,
    onDrop: (acceptedFiles) => {
      setProgress(1);

      compressAndUploadImage(
        acceptedFiles[0],
        uploadImage,
        setPreview,
        name,
        setFieldValue,
        setProgress,
        setIsCompressing,
        setIsCompleted
      );

      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onloadend = (e) => setPreview(reader.result);
    },
  });

  React.useEffect(() => {
    isCompleted === "passed" &&
      showSuccessMsg(enqueueSnackbar, "Image upload complete.");
    if (isCompleted === "failed") {
      showErrorMsg(enqueueSnackbar, "Image upload failed, Try again.");
    }
  }, [isCompleted]);

  return (
    <div>
      <div className={`${classes.dragAndDropCont} div`}>
        <div className="container">
          <Container
            {...getRootProps({ isFocused, isDragAccept, isDragReject })}
          >
            <input {...getInputProps()} />
            <img src={imageIcon} alt="License icon" />
            <Typography
              sx={{
                color: "#757886",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Drag and drop a file or{" "}
            </Typography>
            <button
              style={{
                border: "none",
                background: "transparent",
                color: "#3E5EA9",
                fontSize: "16px",
                lineHeight: "24px",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                open();
              }}
            >
              Browse
            </button>
          </Container>
        </div>
        <div style={errorContainer}>
          <ErrorMessage name={name} component={TextError} />
        </div>
        <aside style={{ marginTop: "1.5rem" }}>
          <Grid item>
            {progress < 100 || isCompressing ? (
              <Grid
                container
                item
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography display={"inline"}>
                  {isCompressing ? "Compressing file" : "Uploading file"}
                </Typography>
                <Loader />
              </Grid>
            ) : preview && isCompleted !== "failed" ? (
              <div style={thumb}>
                <Grid
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    right: "-10px",
                    zIndex: "5",
                  }}
                >
                  <CloseBtn
                    handleClick={() => {
                      setFieldValue(name, null);
                      setPreview("");
                    }}
                  />
                </Grid>
                <div style={thumbInner}>
                  <img src={preview} style={img} />
                </div>
              </div>
            ) : (
              ""
            )}
          </Grid>
        </aside>
      </div>
      <div className={`${classes.fileUpload} div`}>
        <FormikControl
          control="file"
          name={name}
          label=""
          setFieldValue={setFieldValue}
        />
      </div>
    </div>
  );
};

export default DragAndDrop;
