import React, { createContext, useContext, useReducer } from "react";
import { dataReducer } from "./reducers/dataReducer";

const DataContext = createContext();

const initialState = {};

const DataProvider = ({ children }) => {
  const [data, dispatch] = useReducer(dataReducer, initialState);

  return (
    <DataContext.Provider value={{ data, dispatch }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  const context = useContext(DataContext);
  return context?.data;
};

export const useDataDispatch = () => {
  const context = useContext(DataContext);
  return context?.dispatch;
};

export default DataProvider;
