export const dataReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_DATA": {
      const newState = { ...state, ...action.data };
      return newState;
    }

    case "CHANGE_DATA": {
      return action?.data;
    }

    default:
      return state;
  }
};
