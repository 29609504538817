import React, { useState, useCallback } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Create } from "components/pages";
import { scrollToTop } from "helpers/helperFuncs";
/* import { PreviousButton } from "components/Utilities"; */
import HealaLogo from "components/icons/HealaLogo";

const useStyles = makeStyles(() => ({
  backBtn: {
    "@media (max-width:600px)": {
      display: "none",
    },
  },
}));

const BecomePartner = () => {
  const classes = useStyles();
  const pageTop = React.useRef();

  /*   const increment = (count) => {
    setStep(step + count);
    scrollToTop(pageTop);
  }; */

  return (
    <div ref={pageTop} style={{ width: "100%" }}>
      <Grid container direction="column">
        <Grid
          container
          justifyContent={"space-between"}
          alignItems="center"
          direction="column"
          style={{ margin: "1rem 0rem" }}
        >
          {/* {step === 1 ? (
            <Grid
              item
              style={{
                position: "absolute",
                left: "1rem",
                top: "4rem",
              }}
            >
              <div className={classes.backBtn}>
                <PreviousButton step={step} handlePrevious={handlePrevious} />
              </div>
            </Grid>
          ) : null} */}

          <a href="https://heala.ng/" rel="no-referrer">
            <Grid item sx={{ marginTop: "2rem" }}>
              <HealaLogo sx={{ width: 197, height: 70 }} />
            </Grid>
          </a>
        </Grid>

        <Grid item container direction="column" margin="auto">
          <Create />
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(BecomePartner);
