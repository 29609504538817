import React from "react";
import useAlert from "./useAlert";
import useGetItem from "./useGetItem";
import { useLogin } from "./useLogin";
import { useMutation } from "@apollo/client";
import { useDataDispatch } from "context/dataContext";
import { dateMoment } from "components/Utilities/Time";
import {
  createDoctorProfile,
  updateDoctorProfile,
} from "components/graphQL/Mutation";
import { useStepDispatch } from "context/stepContext";

const useCreateProfile = () => {
  const { getItem } = useGetItem();
  const stepDispatch = useStepDispatch();
  const dataDispatch = useDataDispatch();
  const { checkVerificationStatus } = useLogin();
  const { displayAlert, getErrorMsg } = useAlert();
  const [createDoctor] = useMutation(createDoctorProfile);
  const [updateDoctor] = useMutation(updateDoctorProfile);

  const createDocProfile = async (values) => {
    const {
      dob,
      firstName,
      lastName,
      gender,
      specialization,
      image,
      healaId,
      phoneNumber,
      hospital,
      cadre,
      providerId,
    } = values;
    const correctDOB = dateMoment(dob);
    try {
      if (!healaId) {
        displayAlert("error", "heala ID not found!");
        return;
      }
      const { data } = await createDoctor({
        variables: {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          gender,
          specialization,
          image,
          phoneNumber,
          providerId,
          cadre,
          dociId: healaId,
          hospital: hospital.trim(),
          dob: correctDOB,
        },
      });
      const { _id } = data?.createDoctorProfile?.profile;
      localStorage.setItem("profile_id", _id);
      return { isSuccessful: true, data: data?.createDoctorProfile?.profile };
    } catch (err) {
      return { isSuccessful: false, error: err };
    }
  };

  const editDocProfile = async (values) => {
    const {
      id,
      dob,
      firstName,
      lastName,
      gender,
      specialization,
      image,
      phoneNumber,
      hospital,
      cadre,
    } = values;
    const correctDOB = dateMoment(dob);
    try {
      const { data } = await updateDoctor({
        variables: {
          id,
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          gender,
          specialization,
          image,
          phoneNumber,
          cadre,
          hospital: hospital.trim(),
          dob: correctDOB,
        },
      });

      const responseData = data?.updateDoctorProfile?.profile || {};
      displayAlert("success", "Profile updated successfully.");
      dataDispatch({ type: "CHANGE_DATA", data: responseData });
      return checkVerificationStatus(responseData?._id);
    } catch (error) {
      const errMsg = getErrorMsg(error);
      displayAlert("error", errMsg);
    }
  };

  const onSubmitProfileForm = async (values) => {
    try {
      const response = await createDocProfile(values);

      if (response.isSuccessful) {
        dataDispatch({ type: "UPDATE_DATA", data: response?.data });
        displayAlert("success", "Profile created successfully.");
        stepDispatch({ type: "CHANGE_STEP", step: 3 });
      } else if (!response.isSuccessful) {
        const errMsg = getErrorMsg(response?.error);
        if (
          errMsg[0].toLowerCase().includes("profile already exist") ||
          errMsg[0].toLowerCase().includes("profile exist already")
        ) {
          const id = getItem(["_id", "account_id"], "");
          editDocProfile({ id, ...values });
        } else {
          displayAlert("error", errMsg);
        }
      }
    } catch (error) {
      console.error(error);
      const errMsg = getErrorMsg(error);
      displayAlert("error", errMsg);
    }
  };
  return { onSubmitProfileForm };
};

export default useCreateProfile;
