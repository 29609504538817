import React, { createContext, useContext, useReducer } from "react";
import { stepReducer } from "./reducers/stepReducer";

const StepContext = createContext();

const initialState = {
  step: 1,
};

const StepProvider = ({ children }) => {
  const [data, dispatch] = useReducer(stepReducer, initialState);
  return (
    <StepContext.Provider value={{ dispatch, step: data }}>
      {children}
    </StepContext.Provider>
  );
};

export const useStep = () => {
  const context = useContext(StepContext);
  return context?.step;
};

export const useStepDispatch = () => {
  const context = useContext(StepContext);
  return context?.dispatch;
};

export default StepProvider;
