import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";
import { Grid, Typography, Box } from "@mui/material";
import SignUpForm from "components/forms/SignUpForm";
import SignInForm from "components/forms/SignInForm";
import FormContainer from "components/layouts/FormContainer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
};

const Account = ({ question, btnText, handleClick }) => {
  return (
    <Grid
      container
      justifyContent="center"
      spacing={1}
      sx={{
        marginTop: "1rem",
      }}
    >
      <Grid item>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "23px",
            color: "#757886",
          }}
        >
          {question}
        </Typography>
      </Grid>
      <Grid item>
        <button
          style={{
            backgroundColor: "transparent",
            border: "none",
            color: "#3E5EA9",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "23px",
            cursor: "pointer",
          }}
          onClick={() => handleClick()}
        >
          {btnText}
        </button>
      </Grid>
    </Grid>
  );
};

Account.propTypes = {
  question: PropTypes.string,
  btnText: PropTypes.string,
  handleClick: PropTypes.number,
};

const PageOne = () => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  React.useEffect(() => {
    ["account_id", "email", "heala_id", "profile_id", "token"].map((text) =>
      localStorage.setItem(text, "")
    );
  }, []);

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Box
          sx={{
            width: 500,
            margin: "1rem",
            borderRadius: "16px",
            boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.02)",
          }}
        >
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              {/*  WRITE CONTEXT TO MANAGE NEXT STEP ETC. to avoid PROP DRILLING */}
              <FormContainer>
                <SignUpForm />
                <Account
                  question="Already have an account?"
                  btnText="Login"
                  handleClick={() => setValue(1)}
                />
              </FormContainer>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <FormContainer>
                <SignInForm />
                <Account
                  question="Don't have an account?"
                  btnText="Create Account"
                  handleClick={() => setValue(0)}
                />
              </FormContainer>
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Grid>
    </>
  );
};
export default PageOne;

PageOne.propTypes = {
  handleNext: PropTypes.func,
  handleNext2: PropTypes.func,
  step: PropTypes.string,
};
