import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { useData } from "context/dataContext";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import { CustomButton } from "components/Utilities";
import { pageTwoUseStyles } from "styles/formStyles";
import useCreateProfile from "hooks/useCreateProfile";
import FormContainer from "components/layouts/FormContainer";
import { PhoneNumberInput } from "components/validation/Input";
import FormikControl from "components/validation/FormikControl";
import { pageTwoValidationSchema } from "helpers/formValidation";
import { formatPhoneNumber, getYearsBack, pick } from "helpers/helperFuncs";
import {
  genderOptions,
  selectLevelOption,
  specializationOptions,
} from "helpers/mockData";

const PageTwo = () => {
  const theme = useTheme();
  const userData = useData();
  const classes = pageTwoUseStyles();
  const [countryCode] = useState("+234");
  const id = localStorage.getItem("heala_id");
  const { onSubmitProfileForm } = useCreateProfile();
  const [endDateLimit, setEndDataLimit] = useState("");
  const [countryCodeOptions] = useState([{ key: "+234", value: "+234" }]);
  const data = {
    providerId: userData?.providerId,
    hospital: userData?.hospital ? userData?.hospital : "Nil",
  };

  const greenButton = {
    background: theme.palette.success.main,
    hover: theme.palette.success.light,
    active: theme.palette.primary.dark,
  };

  useEffect(() => {
    setEndDataLimit(getYearsBack(18));
  }, []);

  const formatedInitialValues = React.useMemo(() => {
    const values = pick(userData, [
      "firstName",
      "lastName",
      "gender",
      "phoneNumber",
      "specialization",
      ["dob", null],
      "cadre",
      ["picture", null],
    ]);
    return {
      ...values,
      phoneNumber: formatPhoneNumber(values?.phoneNumber),
    };
  }, [userData]);

  const onSubmit = async (values) => {
    values.phoneNumber = `${countryCode}${values.phoneNumber}`;
    const newValues = {
      image: values?.picture,
      ...values,
    };

    await onSubmitProfileForm({ healaId: id, ...data, ...newValues });
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid
          item
          container
          direction="column"
          md={4}
          sm={12}
          gap={5}
          sx={{
            margin: "auto",
          }}
        >
          <FormContainer>
            <Formik
              initialValues={formatedInitialValues}
              validationSchema={pageTwoValidationSchema}
              validateOnChange={true}
              validateOnBlur={true}
              validateOnMount={false}
              onSubmit={(values) => onSubmit(values)}
            >
              {({ isSubmitting, setFieldValue, setValues, values }) => {
                return (
                  <Form>
                    <Grid
                      container
                      md={12}
                      margin="auto"
                      gap={3}
                      sx={{ backgroundColor: "#fff" }}
                    >
                      <Grid
                        item
                        container
                        justifyContent="center"
                        marginBottom="14px"
                        gap={2}
                      >
                        <Grid item container justifyContent="center">
                          <Grid item>
                            <Typography
                              sx={{
                                color: "#010101",
                                fontSize: "20px",
                                fontWeight: 500,
                              }}
                            >
                              CREATE PROFILE
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        container
                        justifyContent="space-around"
                        gap={1}
                      >
                        <FormikControl
                          control="input"
                          name="firstName"
                          placeholder="Enter your First Name"
                          label="First Name"
                          isRequired
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent="space-around"
                        gap={1}
                      >
                        <FormikControl
                          control="input"
                          name="lastName"
                          label="Last Name"
                          placeholder="Enter Your last Name"
                          isRequired
                        />
                      </Grid>
                      <Grid item gap={2} sx={{ width: "100%" }}>
                        <PhoneNumberInput
                          label="Phone number"
                          isRequired
                          name="phoneNumber"
                          countryCodeValue={countryCode}
                          countryCodeOptions={countryCodeOptions}
                          placeholder="8012345678"
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent="space-around"
                        gap={2}
                      >
                        <FormikControl
                          control="select"
                          name="cadre"
                          placeholder="Select Level"
                          label="Select Medical Level"
                          options={selectLevelOption}
                          onChange={(e) => {
                            const value = e?.target?.value;
                            setFieldValue("cadre", value);
                            if (
                              value === "House officer" ||
                              value === "Medical officer (MO)"
                            ) {
                              setFieldValue("specialization", "Nil");
                            } else {
                              setFieldValue("specialization", "");
                            }
                          }}
                          isRequired
                        />
                      </Grid>
                      {(values?.cadre && values?.cadre === "Registrar") ||
                      values?.cadre === "Senior Registrar" ||
                      values?.cadre === "Consultant" ? (
                        <Grid
                          item
                          container
                          justifyContent="space-around"
                          gap={2}
                        >
                          <FormikControl
                            control="select"
                            name="specialization"
                            placeholder="Select Specialization"
                            label="Specialization"
                            options={specializationOptions}
                            isRequired
                          />
                        </Grid>
                      ) : (
                        ""
                      )}
                      <Grid
                        item
                        container
                        justifyContent="space-around"
                        gap={2}
                      >
                        <FormikControl
                          control="date"
                          name="dob"
                          label="DOB"
                          setFieldValue={setFieldValue}
                          setValues={setValues}
                          isRequired
                          endDate={endDateLimit}
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent="space-around"
                        gap={2}
                      >
                        <FormikControl
                          control="select"
                          name="gender"
                          label="Gender"
                          options={genderOptions}
                          placeholder="Select Gender"
                          isRequired
                        />
                      </Grid>

                      <Grid item container justifyContent="space-around">
                        <FormikControl
                          control="file"
                          name="picture"
                          label="Upload Your Image"
                          setFieldValue={setFieldValue}
                          isRequired
                        />
                      </Grid>

                      <Grid item container md={12} sm={10} margin="auto">
                        <CustomButton
                          variant="contained"
                          title="Continue"
                          type={greenButton}
                          className={classes.btn}
                          isSubmitting={isSubmitting}
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </FormContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default PageTwo;
