import React from "react";
import { useData } from "context/dataContext";

const useGetItem = () => {
  const data = useData();
  const getItem = (items, itemDefault) => {
    let item = null;

    for (let index = 0; index < items.length; index++) {
      if (data[items[index]]) {
        item = data[items[index]];
        break;
      } else if (localStorage.getItem(items[index])) {
        item = localStorage.getItem(items[index]);
        break;
      }
    }

    return item || itemDefault;
  };

  return { getItem };
};

export default useGetItem;
